/* eslint-disable camelcase */
import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FieldArray, FormErrors, getFormSyncErrors, InjectedFormProps, reduxForm } from "redux-form";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import UserCert from "../../../../commons/model/UserCert";
import { UserRoleValue } from "../../../../commons/model/UserRole";
import TaskExploitationList from "../../../../commons/ws/exploitation/TaskExploitationList";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import Col from "../../../../components/Col";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormSelect from "../../../../components/form/FormSelect";
import Row from "../../../../components/Row";
import Config from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_USERS } from "../../../../routing/Routes";
import { changeUserPassword } from "../../../../utils/AuthManager";
import { FieldArrayErrors } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import { Int, isHEXColorValid, isNifNiePersonValid, isValidPassword } from "../../../../utils/StringUtils";
import UserCertTooltipInfo from "../UserCertTooltipInfo";
import EditPasswordModal, { EditPasswordModalData } from "./EditPasswordModal";
import RoleFieldArray, { RoleFieldArrayProps } from "./RoleFieldArray";
import UserCardFormAdminReducer from "./UserCardFormAdminReducer";
import UserTypeFieldArray from "./UserTypeFieldArray";

const FORM_NAME = "UserCardFormAdmin";

export interface UserCardFormAdminData {
    name: string;
    last_name: string;
    email: string;
    password: string;
    username: string;
    nif: string;
    suma_code: string;
    recognition_sw_license: string;
    color: string;
    exploitation_id?: string;
    role_ids: string[];
    user_type_ids: string[];
    user_cert?: UserCert;
}

interface UserCardFormExtProps {
    userId?: string;
    initialValues?: Partial<UserCardFormAdminData>;
    onSubmit: (data: UserCardFormAdminData)=> void;
    isCurrentUser: boolean;
    parentLoading: boolean;
    needsPassword: boolean;
    currentUserIsAdmin: boolean;
}

enum UserCardFormAdminFields {
    NAME = "name",
    LAST_NAME = "last_name",
    EMAIL = "email",
    PASSWORD = "password",
    USERNAME = "username",
    NIF = "nif",
    SUMA_CODE = "suma_code",
    RECOGNITION_SW_LICENSE = "recognition_sw_license",
    COLOR = "color",
    EXPLOITATION_ID = "exploitation_id",
    ROLE_IDS = "role_ids",
    USER_TYPE_IDS = "user_type_ids",
}

interface UserCardFormAdminProps extends InjectedFormProps<UserCardFormAdminData> {
    onSubmit: (data: UserCardFormAdminData)=> void;
    initialValues: Partial<UserCardFormAdminData>;
    formState: { values: UserCardFormAdminData };
}

const mapStateToPropsGeneric = (state: any) => ( {
  formState: state.form[FORM_NAME],
  synchronousError: getFormSyncErrors(FORM_NAME)(state),
} );
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  UserCardFormAdminReducer.autoMapToProps(),
  mapStateToPropsGeneric,
);

interface UserFormAdminScreenState {
    modal: boolean;
}

type Props = UserCardFormAdminProps & UserCardFormExtProps & typeof mapStateToProps;

class UserCardFormAdmin extends React.Component<Props, UserFormAdminScreenState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      modal: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskExploitationList( {
      limit: 0,
    } ).execute();
  }

  private onCloseModal = (): void => {
    this.setState( {
      modal: !this.state.modal,
    } );
  };

  private onEditPassword = (values: EditPasswordModalData): void => {
    if (this.props.userId)
      changeUserPassword(values.password, this.props.userId);
    else
      Alert.error(I18n.tr(I18nKeys.NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA));

    this.onCloseModal();
  };

  private existColorFieldError(): boolean {
    if (this.props.formState && this.props.formState.values) {
      const colorError = validate(this.props.formState.values, {
      } as UserCardFormExtProps).color;

      return !!colorError;
    }

    return false;
  }

  public render(): React.ReactNode {
    const {parentLoading, handleSubmit, onSubmit, initialValues, invalid, pristine, isCurrentUser, exploitationList,
      formState} = this.props;
    const exploitationOptions = exploitationList.map((exploitation) => ( {
      value: exploitation.id,
      name: exploitation.name,
    } ));
    let hideSelectExploitation: boolean = true;
    let showSelectUserType: boolean = false;

    if (formState.values && formState.values.role_ids) {
      hideSelectExploitation = formState.values.role_ids.includes(UserRoleValue.ADMIN);
      showSelectUserType = formState.values.role_ids.includes(UserRoleValue.USER_APP);

      if (!showSelectUserType && formState.values.user_type_ids && formState.values.user_type_ids.length !== 0)
        this.props.change(UserCardFormAdminFields.USER_TYPE_IDS, []);
    }

    const invalidColorField = this.existColorFieldError();

    return (
      <React.Fragment>
        <Card loading={parentLoading}>
          <CardHeader title={I18n.tr(initialValues ? I18nKeys.USUARIO : I18nKeys.NUEVO_USUARIO)}/>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.NOMBRE)}
                  name={UserCardFormAdminFields.NAME}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
                <Field
                  label={I18n.tr(I18nKeys.APELLIDO)}
                  name={UserCardFormAdminFields.LAST_NAME}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.EMAIL)}
                  name={UserCardFormAdminFields.EMAIL}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                  disabled={isCurrentUser}
                />
                {!initialValues &&
                                <Field
                                  label={I18n.tr(I18nKeys.CONTRASENA)}
                                  name={UserCardFormAdminFields.PASSWORD}
                                  component={FormInput}
                                  type="password"
                                  col={{
                                    md: 6,
                                    lg: 6,
                                  }}
                                />}
                <Row>
                  <Col md={3} lg={3}>
                    {initialValues &&
                                        <Button
                                          text={I18n.tr(I18nKeys.CAMBIAR_CONTRASENA)}
                                          onClick={this.onCloseModal}
                                          type={"button"}
                                          block={true}
                                          className={"btn-lg btn-primary"}
                                        />}
                  </Col>
                </Row>
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.NIF)}
                  name={UserCardFormAdminFields.NIF}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                  disabled={isCurrentUser}
                />
                <Field
                  label={I18n.tr(I18nKeys.NOMBRE_DE_USUARIO)}
                  name={UserCardFormAdminFields.USERNAME}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.CODIGO_SUMA)}
                  name={UserCardFormAdminFields.SUMA_CODE}
                  component={FormInput}
                  col={{
                    md: 3,
                    lg: 3,
                  }}
                  type={"number"}
                  maxLength={2}
                  max={99}
                  min={1}
                />
                <Field
                  label={I18n.tr(I18nKeys.LICENCIA_RECONOCIMIENTO_MATRICULAS)}
                  name={UserCardFormAdminFields.RECOGNITION_SW_LICENSE}
                  component={FormInput}
                  col={{
                    md: 3,
                    lg: 3,
                  }}
                />
                <Field
                  label={I18n.tr(I18nKeys.COLOR)}
                  name={UserCardFormAdminFields.COLOR}
                  component={FormInput}
                  type={"color"}
                  col={{
                    md: 2,
                    lg: 2,
                  }}
                  classForm={"form-color"}
                  classNameInput={`select-color ${invalidColorField && "error"}`}
                  showError={invalidColorField}
                />
                <Col lg={3} md={3} className={"m-t-15"}>
                  <label>{I18n.tr(I18nKeys.FIRMA_DIGITAL)}</label>
                  <div className={"m-t-5"}>
                    {initialValues && initialValues.user_cert ?
                      <UserCertTooltipInfo userCert={initialValues.user_cert}
                        key={initialValues.user_cert.end_date}/> :
                      I18n.tr(I18nKeys.NO_EXISTE_FIRMA_DIGITAL)
                    }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6}>
                  <FieldArray<RoleFieldArrayProps>
                    name={UserCardFormAdminFields.ROLE_IDS}
                    // @ts-ignore
                    component={RoleFieldArray}
                    showFirstOption={!initialValues}
                    isCurrentUser={isCurrentUser}
                  />
                </Col>
                {showSelectUserType &&
                                <Col md={6} lg={6} className={"right-field"}>
                                  <FieldArray
                                    name={UserCardFormAdminFields.USER_TYPE_IDS}
                                    // @ts-ignore
                                    component={UserTypeFieldArray}
                                    showFirstOption={!initialValues}
                                  />
                                </Col>}
              </Row>
              {this.props.currentUserIsAdmin && !hideSelectExploitation &&
                            <Row>
                              <Field
                                label={I18n.tr(I18nKeys.EXPLOTACION)}
                                name={UserCardFormAdminFields.EXPLOITATION_ID}
                                component={FormSelect}
                                options={exploitationOptions}
                                blankOptionText={" "}
                                col={{
                                  md: 6,
                                  lg: 6,
                                }}
                              />
                            </Row>
              }
              <FormCardFooter
                invalid={invalid}
                pristine={pristine}
                isUpdate={!!initialValues}
                cancelHandler={() => goToRoute(ROUTE_ADMIN_USERS)}/>
            </form>
          </CardBody>
        </Card>
        {initialValues &&
                <EditPasswordModal
                  show={this.state.modal}
                  onSubmit={this.onEditPassword}
                  onClose={this.onCloseModal}
                />}
      </React.Fragment>
    );
  }
}

function validateRolesFields(values: UserCardFormAdminData): FieldArrayErrors[] {
  const roleErrors: FieldArrayErrors[] = [];
  const selectedRoles: string[] = [];

  values.role_ids.forEach((role, index) => {
    roleErrors[index] = "";

    if (role === "" || role === "-1")
      roleErrors[index] = I18n.tr(I18nKeys.ROL_OBLIGATORIO);

    if (role === UserRoleValue.ADMIN && values.role_ids.length !== 1)
      roleErrors[index] = I18n.tr(I18nKeys.EL_USUARIO_ADMINISTRADOR_NO_PUEDE_TENER_MAS_ROLES);

    const findedRole = selectedRoles.find((selectedRole) => selectedRole === role);

    if (findedRole)
      roleErrors[index] = I18n.tr(I18nKeys.ROL_REPETIDO);
    else
      selectedRoles.push(role);
  } );

  return roleErrors;
}

function validateUserTypeFields(values: UserCardFormAdminData): FieldArrayErrors[] {
  const userTypeErrors: FieldArrayErrors[] = [];
  const selectedUserType: string[] = [];

  values.user_type_ids.forEach((userType, index) => {
    userTypeErrors[index] = "";

    if (userType === "" || userType === "-1")
      userTypeErrors[index] = I18n.tr(I18nKeys.TIPO_DE_USUARIO_OBLIGATORIO);

    const findedUserType = selectedUserType.find((currentUserType) => currentUserType === userType);

    if (findedUserType)
      userTypeErrors[index] = I18n.tr(I18nKeys.TIPO_DE_USUARIO_REPETIDO);
    else
      selectedUserType.push(userType);
  } );

  return userTypeErrors;
}

function validate(values: UserCardFormAdminData, props: UserCardFormExtProps) {
  const errors: FormErrors<UserCardFormAdminData, FieldArrayErrors> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.last_name || values.last_name.length === 0)
    errors.last_name = I18n.tr(I18nKeys.APELLIDO_OBLIGATORIO);

  if (!values.email || values.email.length === 0)
    errors.email = I18n.tr(I18nKeys.EMAIL_OBLIGATORIO);

  if (props.needsPassword) {
    if (!values.password || values.password.length === 0)
      errors.password = I18n.tr(I18nKeys.CONTRASENA_OBLIGATORIA);
    else if (values.password.length < Config.MIN_PASSWORD_LENGTH)
      errors.password = I18n.tr(I18nKeys.LA_CONTRASENA_TIENE_POCOS_CARACTERES);
    else if (!isValidPassword(values.password))
      errors.password = I18n.tr(I18nKeys.LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES);
  }

  if (!values.username || values.username.length === 0)
    errors.username = I18n.tr(I18nKeys.NOMBRE_DE_USUARIO_OBLIGATORIO);

  if (values.nif && values.nif.length > 0) {
    if (!isNifNiePersonValid(values.nif))
      errors.nif = I18n.tr(I18nKeys.EL_NIF_NO_ES_VALIDO);
  }

  if (!values.suma_code || values.suma_code.length === 0)
    errors.suma_code = I18n.tr(I18nKeys.CODIGO_SUMA_OBLIGATORIO);

  if (values.suma_code && (Int(values.suma_code) < 1 || Int(values.suma_code) > 99))
    errors.suma_code = I18n.tr(I18nKeys.SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_99);

  if (!values.color || values.color.length === 0)
    errors.color = I18n.tr(I18nKeys.COLOR_OBLIGATORIO);
  else if (!isHEXColorValid(values.color))
    errors.color = I18n.tr(I18nKeys.CODIGO_DE_COLOR_NO_VALIDO);

  if (values.role_ids && !values.role_ids.includes(UserRoleValue.ADMIN)) {
    if (!values.exploitation_id || values.exploitation_id === "-1")
      errors.exploitation_id = I18n.tr(I18nKeys.EXPLOTACION_OBLIGATORIO);
  }

  if (values.role_ids) {
    errors.role_ids = validateRolesFields(values)
      .map((fieldArrayError) => fieldArrayError.toString());

    if (values.role_ids.includes(UserRoleValue.USER_APP)) {
      if (values.user_type_ids)
      {
        errors.user_type_ids = validateUserTypeFields(values)
          .map((fieldArrayError) => fieldArrayError.toString());
      }
    }
  }

  return errors;
}

export default compose(
  reduxForm<UserCardFormAdminData, UserCardFormExtProps, FieldArrayErrors>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(UserCardFormAdmin) as React.ComponentType<UserCardFormExtProps>;
