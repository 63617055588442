import { METHOD } from "@cuatroochenta/co-generic-request";
import { sha256 } from "js-sha256";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import Password from "../../model/Password";
import { generateRoute } from "../../utils/Router";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskUserPasswordEdit extends BaseGenericRequest <Password, TaskResponse<any>> {
  private readonly data: Password;

  private readonly wrapErrorCodeWithMessage: boolean;

  public constructor(password: Password, userId: string, wrapErrorCodeWithMessage = false) {
    super(METHOD.POST, generateRoute(Urls.URL_USER_CHANGE_PASSWORD, {
      uuid: userId,
    } ));
    this.wrapErrorCodeWithMessage = wrapErrorCodeWithMessage;

    this.setErrorMessage(401, I18n.tr(I18nKeys.NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA));
    this.setErrorMessage(404, I18n.tr(I18nKeys.EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE));
    this.setErrorMessage(411, I18n.tr(I18nKeys.LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES));

    this.data = password;
  }

  protected parseResponse(responseObj: any) {
    if (!this.wrapErrorCodeWithMessage)
      return super.parseResponse(responseObj);

    if (responseObj.success === false)
    {throw new Error(JSON.stringify( {
      errorMessage: responseObj.message || this.errorMessages[responseObj.code],
      errorCode: responseObj.code,
    } ));}

    return super.parseResponse(responseObj);
  }

  protected getRequest(): Password {
    return {
      ...this.data,
      password: sha256(this.data.password),
    };
  }
}
