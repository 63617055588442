import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Report from "../../../../commons/model/Report";
import WorkdayPosition from "../../../../commons/model/WorkdayPosition";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";
import TaskWorkdayResume, { WorkdayResumeCriteria } from "../../../../commons/ws/workdayResume/TaskWorkdayResume";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import Col from "../../../../components/Col";
import FormCol from "../../../../components/form/FormCol";
import FormInputOption from "../../../../components/form/FormInputOption";
import MapLocationPicker, {MapLocationPickerProps,
  MarkerControllerProps,
  MarkerReportProps} from "../../../../components/MapLocationPicker";
import Row from "../../../../components/Row";
import Config from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_CONTROLLERS } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";
import ControllerSituationMapCompanyUserReducer from "./ControllerSituationMapCompanyUserReducer";

const FORM_NAME = "ControllerSituationMapCardCompanyUser";
const mapStateToProps = ControllerSituationMapCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

interface State {
    currentCriteria: WorkdayResumeCriteria;
}

class ControllerSituationMapCardCompanyUser extends React.Component<Props, State> {
  private mapContainer;

  public constructor(props: Props) {
    super(props);
    this.state = {
      currentCriteria: {
        user_ids: ["-1"],
        date: "",
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskUserList( {
      limit: 0,
    } ).execute();
  }

  private onControllerSelect = (selectControllerId: string): void => {
    this.setState( {
      currentCriteria: {
        ...this.state.currentCriteria,
        user_ids: [selectControllerId],
      },
    } );

    if (selectControllerId !== "-1" && this.state.currentCriteria.date.length !== 0)
    {new TaskWorkdayResume( {
      user_ids: [selectControllerId],
      date: this.state.currentCriteria.date,
    } ).execute();}
  };

  private onDateSelect = (date?: Date | null): void => {
    const newCriteria: WorkdayResumeCriteria = {
      ...this.state.currentCriteria,
      date: date ? date.toISOString() : "",
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );

    if (date && this.state.currentCriteria.user_ids.length !== 0 && this.state.currentCriteria.user_ids[0] !== "-1")
    {new TaskWorkdayResume( {
      user_ids: this.state.currentCriteria.user_ids,
      date: date.toISOString(),
    } ).execute();}
  };

  public render(): React.ReactNode {
    const {loading} = this.props;

    return (
      <Card loading={loading}>
        <CardHeader title={I18n.tr(I18nKeys.MAPA_DE_SITUACION)}
        >
          {this.renderHeaderContent()}
        </CardHeader>
        <CardBody className={"table-responsive"}>
          {this.renderCardBody()}
        </CardBody>

        <Row className={"p-t-20 p-b-40 m-r-5 m-l-0"}>
          <Col sm={8} md={9} lg={10}/>
          <Col sm={4} md={3} lg={2}>
            <Button
              text={I18n.tr(I18nKeys.CANCELAR)}
              onClick={() => goToRoute(ROUTE_COMPANY_USER_CONTROLLERS)}
              type={"button"}
              block={true}
              className={"btn-lg btn-default"}
            />
          </Col>
        </Row>
      </Card>
    );
  }

  private renderHeaderContent = (): React.ReactNode => {
    const {userList} = this.props;
    const controllerOptions = userList.map((user) => ( {
      value: user.id,
      name: `${user.name} ${user.last_name}`,
    } ));

    return (
      <Row>
        <FormCol md={3} lg={3}>I18n
          <div className={"form-group"}>
            <label className={"main-label"}>{I18n.tr(I18nKeys.CONTROLADOR)}</label>
            <select className={"form-control show-tick bootstrap-select"}
              value={this.state.currentCriteria.user_ids[0]}
              onChange={(event) => this.onControllerSelect(event.target.value)}
            >
              <option key={-1} value={-1}>{""}</option>
              {controllerOptions.map((option: FormInputOption, index) => (<option key={index} value={option.value}>{option.name}</option>))}
            </select>
          </div>
        </FormCol>
        <FormCol md={3} lg={3}>
          <div className={"form-group"}>
            <div className={"form-line"}>
              <label>{I18n.tr(I18nKeys.FECHA)}</label>
              <input type={"date"} className="form-control"
                onChange={(e) => this.onDateSelect(e.target.valueAsDate)}/>
            </div>
          </div>
        </FormCol>
      </Row>
    );
  };

  private renderCardBody(): React.ReactNode {
    const {workdayResume} = this.props;
    const existWorkdayResume = workdayResume.length !== 0;
    let markerReportData: MarkerReportProps[] = [];
    let markerControllerData: MarkerControllerProps[] = [];
    let isControllerActive = false;
    let currentPositionController = {
    } as MarkerControllerProps;

    if (existWorkdayResume) {
      const reports = workdayResume[0].reports && workdayResume[0].reports.length !== 0 ? workdayResume[0].reports : [];

      markerReportData = reports.map((report: Report) => {
        const dateHour = DateFormatter.formatDatetime(report.date);

        return {
          position: {
            lat: report.latitude,
            lng: report.longitude,
          },
          reportData: {
            controller: workdayResume ? `${workdayResume[0].user.name} ${workdayResume[0].user.last_name}` : "",
            licensePlate: report.vehicle.license_plate,
            reportDate: dateHour.split("-")[0],
            reportHour: dateHour.split("-")[1].slice(1),
          },
        };
      } );

      const controllerPositions = workdayResume[0].positions.length !== 0 ? workdayResume[0].positions : [];
      const sortedControllerPositionsByDate: MarkerControllerProps[] = controllerPositions
        .map((controllerPosition: WorkdayPosition) => ( {
          position: {
            lat: controllerPosition.latitude,
            lng: controllerPosition.longitude,
          },
          date: controllerPosition.date,
        } ))
        .sort((controllerPositionA, controllerPositionB) =>
          moment(controllerPositionA.date).valueOf() - moment(controllerPositionB.date).valueOf());

      isControllerActive = !!workdayResume[0].state;

      if (isControllerActive)
        currentPositionController = sortedControllerPositionsByDate[sortedControllerPositionsByDate.length - 1];
      else
        markerControllerData = sortedControllerPositionsByDate;
    }

    let defaultPosition: any = Config.DEFAULT_MAP_POSITION;

    if (markerControllerData.length !== 0)
      defaultPosition = markerControllerData[0].position;
    else if (currentPositionController.position)
      defaultPosition = currentPositionController;
    else if (markerReportData.length !== 0)
      defaultPosition = markerReportData[0].position;

    let mapLocationPickerProps: MapLocationPickerProps = {
      google: this.mapContainer,
      defaultPosition,
      detailMode: false,
      reportMarkers: markerReportData,
      showMainMarker: false,
    };

    mapLocationPickerProps = isControllerActive ?
      {
        ...mapLocationPickerProps,
        currentPositionController,
      }
      : {
        ...mapLocationPickerProps,
        controllerMarkers: markerControllerData,
      };

    return (
      <Row ref={(node) => {
        this.mapContainer = node;

        return node;}}
      className={"gmap"}
      >
        <Field name={"controllerPositions"}
          component={MapLocationPicker}
          {...mapLocationPickerProps}
        />
      </Row>);
  }
}

export default compose(
  reduxForm<{}, {}>( {
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(ControllerSituationMapCardCompanyUser as React.ComponentType<{}>);
