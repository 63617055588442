import React from "react";
import { Modal } from "react-bootstrap";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import Alert from "../../../../base/alerts/Alert";
import { BaseModalProps } from "../../../../base/modal/BaseModal";
import I18n from "../../../../commons/I18n/I18n";
import Button from "../../../../components/buttons/Button";
import Col from "../../../../components/Col";
import FormInput from "../../../../components/form/FormInput";
import FormSubmit from "../../../../components/form/FormSubmit";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { isValidPassword } from "../../../../utils/StringUtils";

export interface EditPasswordModalData {
    password: string;
    repassword: string;
}

interface EditPasswordModalProps extends BaseModalProps {
    onSubmit: (data: EditPasswordModalData)=> void;
    forceChange?: boolean;
}

type Props = BaseModalProps & EditPasswordModalProps & InjectedFormProps<EditPasswordModalData>;

class EditPasswordModal extends React.Component<Props> {
  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.show !== prevProps.show) {
      if (this.props.show)
        this.props.reset();
    }
  }

  public render(): React.ReactNode {
    const {show, onSubmit, handleSubmit, onClose, pristine, invalid, forceChange = false} = this.props;

    return (
      <Modal onHide={forceChange ?
        () => Alert.info(I18n.tr(I18nKeys.POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA)) :
        onClose
      } show={show}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.tr(I18nKeys.CAMBIAR_CONTRASENA)}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>

          <Modal.Body>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NUEVA_CONTRASENA)}
                name={"password"}
                component={FormInput}
                type={"password"}
                col={{
                  md: 12,
                  lg: 12,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.REPITE_LA_CONTRASENA)}
                name={"repassword"}
                component={FormInput}
                type={"password"}
                col={{
                  md: 12,
                  lg: 12,
                }}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {forceChange ?
                <Col xs={6} sm={6} md={9} lg={9}/> :
                <>
                  <Col xs={0} sm={0} md={6} lg={6}/>
                  <Col sm={6} md={3} lg={3}>
                    <Button
                      text={I18n.tr(I18nKeys.CANCELAR).toUpperCase()}
                      onClick={onClose}
                      block={true}
                      className={"btn-lg"}
                    />
                  </Col>
                </>
              }
              <Field
                label={I18n.tr(I18nKeys.ACEPTAR).toUpperCase()}
                name={"submit"}
                component={FormSubmit}
                col={{
                  sm: 6,
                  md: 3,
                  lg: 3,
                }}
                disabled={pristine || invalid}
              />
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

function validate(values: EditPasswordModalData) {
  const errors: FormErrors<EditPasswordModalData> = {
  };

  if (!values.password || values.password.length === 0)
    errors.password = I18n.tr(I18nKeys.LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA);
  else if (!isValidPassword(values.password))
    errors.password = I18n.tr(I18nKeys.LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES);

  if (values.password !== values.repassword)
    errors.repassword = I18n.tr(I18nKeys.LAS_CONTRASENAS_NO_COINCIDEN);

  return errors;
}

export default reduxForm<EditPasswordModalData, EditPasswordModalProps>( {
  validate,
  form: `${EditPasswordModal.name }Form`,
  enableReinitialize: true,
} )(EditPasswordModal as any) as unknown as React.ComponentType<EditPasswordModalProps>;
