/* eslint-disable max-classes-per-file */

import I18n from "../commons/I18n/I18n";
import Language from "../I18n/Language";
import translationsCa from "../I18n/translations/ca.json";
import translationsEn from "../I18n/translations/en.json";
import translationsEs from "../I18n/translations/es.json";

function initializeI18n() {
  I18n.putLocale(Language.ES, translationsEs);
  I18n.putLocale(Language.EN, translationsEn);
  I18n.putLocale(Language.CA, translationsCa);
  I18n.setLocale(Language.ES);
}

initializeI18n();
export default class Config {
  public static VERSION = process.env.REACT_APP_VERSION;

  // eslint-disable-next-line accessor-pairs
  public static get BASE_URL() {
    // eslint-disable-next-line no-restricted-globals
    const { hostname } = location;
    const NODE_PORT = process.env.NODE_PORT ?? 8080;
    const PORT = NODE_PORT;
    const fullUrl = `https://${hostname}:${PORT}`;

    return fullUrl;
  }

  public static PAGER = {
    elementsPage: 10,
  };

  public static AUTH_COOKIE_APP_NAME = "PAVAPARK_AUTH_COOKIE";

  public static MAX_FILE_SIZE_MB = 10;

  public static MIN_PASSWORD_LENGTH = 8;

  public static INACTIVITY_TIMEOUT_MIN = 15;

  public static GOOGLE_API_KEY = "AIzaSyA-g3IETpfEHtHpvfr4-Hq2hXkeW8DbAmM";

  public static DEFAULT_MAP_POSITION = {
    lat: 40.41390113139636,
    lng: -3.704974429443382,
  };
}

export const READED_BROADCAST_IDS = "READED_BROADCAST_IDS";

export class AppIcon {
  public static HOME: string = "home";

  public static USER: string = "person";

  public static USER_GROUP: string = "group";

  public static EXPLOITATION: string = "location_city";

  public static ZONE: string = "place";

  public static BUSINESS_TYPE: string = "category";

  public static FORM: string = "format_align_left";

  public static DASHBOARD: string = "dashboard";

  public static DOCUMENTATION: string = "file_copy";

  public static LOG: string = "av_timer";

  public static ALERT: string = "notification_important";

  public static PARKING: string = "local_parking";

  public static ROUTE: string = "explore";

  public static STREET: string = "map";

  public static PARKING_METER: string = "network_check";

  public static PARKING_METER_INCIDENCE: string = "report_problem";

  public static PARKING_METER_COLLECTION: string = "move_to_inbox";

  public static LAW: string = "gavel";

  public static MASTER_TABLE: string = "center_focus_strong";

  public static ADMIN: string = "business_center";

  public static BLACK_LIST_RULE: string = "settings_applications";

  public static CONTROLLER_REPORT: string = "insert_drive_file";

  public static CHAT: string = "chat_bubble";

  public static CLOSE: string = "cancel";

  public static CAMERA: string = "photo_camera";

  public static POST_ADD: string = "post_add";

  public static REPORT: string = "report";

  public static REPORT_OFF: string = "report_off";

  public static MAP: string = "streetview";

  public static REPORT_MARKER: string = "announcement";

  public static CAR: string = "directions_car";

  public static USER_PROFILE: string = "person_outline";

  public static EXIT_ACCOUNT: string = "exit_to_app";

  public static ARROW_DOWN_DROPDOWN: string = "keyboard_arrow_down";

  public static SEND: string = "send";

  public static FOLDER: string = "folder";

  public static ATTACHMENT: string = "attachment";

  public static ATTACH_FILE: string = "attach_file";

  public static DIGITAL_SIGN: string = "description";

  public static DIGITAL_SIGN_ENABLED: string = "create";

  public static ADD: string = "add";

  public static REMOVE: string = "remove";

  public static ADD_CIRCLE: string = "add_circle";

  public static REMOVE_CIRCLE: string = "remove_circle";

  public static SETTINGS: string = "settings";

  public static ARROW_FORWARD: string = "arrow_forward_ios";

  public static EURO: string = "euro";
}
