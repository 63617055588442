import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../../commons/I18n/I18n";
import { ReportSentToProcessStateIds } from "../../../../../commons/model/ReportSentToProcessState";
import TaskLawList from "../../../../../commons/ws/law/TaskLawList";
import { ReportCriteriaProps, ReportCustomCriteria } from "../../../../../commons/ws/report/TaskReportList";
import TaskReportSentToProcessState from "../../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessState";
import TaskReportStateList from "../../../../../commons/ws/reportState/TaskReportStateList";
import TaskRouteList from "../../../../../commons/ws/route/TaskRouteList";
import TaskStreetList from "../../../../../commons/ws/street/TaskStreetList";
import TaskUserControllerList from "../../../../../commons/ws/user/TaskUserControllerList";
import TaskUserRatifierList from "../../../../../commons/ws/user/TaskUserRatifierList";
import TaskVehicleList from "../../../../../commons/ws/vehicle/TaskVehicleList";
import { CardSectionCollapsed } from "../../../../../components-new/CardSectionCollapsed";
import Button from "../../../../../components/buttons/Button";
import Col from "../../../../../components/Col";
import { FilterDate, FilterInputSearch, FilterSelect, selectOptionsBuilder, typeaheadOptionsBuilder } from "../../../../../components/filter";
import { FilterTypeahead } from "../../../../../components/filter/FilterTypeahead";
import FormCol from "../../../../../components/form/FormCol";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import DomainReducer from "../../../../../redux/DomainReducer";
import { DateHelpers } from "../../../../../utils/DateFormatter";
// eslint-disable-next-line import/no-cycle
import ReportListCompanyUserStorageManager from "../ReportListCompanyUserStorageManager";

const searchVehicle = (query: string) => {
  new TaskVehicleList( {
    limit: 0,
    search: query,
  } ).execute();
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface FilterValues {
    reportNumber: string;
    startDate: string;
    endDate: string;
    vehicleId: string;
    routeId: string;
    lawId: string;
    controllerId: string;
    ratifierId: string;
    streetId: string;
    reportStateId: string;
    ratified: string;
    reportSentToProcessStateId: string;
    controllerSignature: string;
    ratifierSignature: string;
}

export type ReportListCompanyUserFilterValues = FilterValues;

const INITIAL_FILTER_VALUES: FilterValues = {
  reportNumber: "",
  startDate: "",
  endDate: "",
  vehicleId: "",
  routeId: "",
  lawId: "",
  controllerId: "",
  ratifierId: "",
  streetId: "",
  reportStateId: "",
  ratified: "",
  reportSentToProcessStateId: "",
  controllerSignature: "",
  ratifierSignature: "",
};

interface InnerProps {
    isComplaintViewerSent: boolean;
    onAddCriteria: (newCriteria: ReportCustomCriteria)=> void;
    onClearCriteria: () => ( {} );
}

const mapStateToProps = DomainReducer.autoMapToProps();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPERTIES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

type Props = InnerProps & typeof mapStateToProps;

interface State {
    filterValues: FilterValues;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ReportListCompanyUserFilter extends React.Component<Props, State> {
  private refReportNumber;

  private refStartDate;

  private refEndDate;

  private refFilterVehicle;

  private refFilterRoute;

  private refFilterLaw;

  private refFilterUserController;

  private refFilterUserRatifier;

  private refFilterStreet;

  private refFilterReportState;

  private refFilterRatified;

  private refFilterReportSentToProcessState;

  private refFilterControllerSignature;

  private refFilterRatifierSignature;

  public constructor(props: Props) {
    super(props);

    const filters: FilterValues | null = ReportListCompanyUserStorageManager.getFilters();
    const complaintFilters: Partial<FilterValues> = this.props.isComplaintViewerSent
      ? {
        reportSentToProcessStateId: ReportSentToProcessStateIds.ENVIADA,
      }
      : {
      };

    this.state = {
      filterValues: filters
        ? {
          ...filters,
          ...complaintFilters,
        }
        : {
          ...INITIAL_FILTER_VALUES,
          ...complaintFilters,
        },
    };
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // El valor límite a 0 permite traer todos los elementos (no paginación).
  public componentDidMount(): void {
    this.initData();

    if (this.handleCheckFiltersApplied())
      this.props.onAddCriteria(this.handleParseFiltersToDataCriteria());
  }

  // eslint-disable-next-line class-methods-use-this
  public async initData() {
    await new TaskRouteList( {
      limit: 0,
    } ).executeAsPromise();
    await new TaskLawList( {
      limit: 0,
    } ).executeAsPromise();
    await new TaskStreetList( {
      limit: 0,
    } ).executeAsPromise();
    await new TaskReportStateList( {
      limit: 0,
    } ).executeAsPromise();
    await new TaskReportSentToProcessState( {
      limit: 0,
    } ).executeAsPromise();
    await new TaskUserControllerList( {
      limit: 0,
    } ).executeAsPromise();
    await new TaskUserRatifierList( {
      limit: 0,
    } ).executeAsPromise();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  public render() {
    const {filterValues} = this.state;
    const {vehicles,
      vehiclesLoading,
      routes,
      routesLoading,
      laws,
      lawsLoading,
      controllers,
      controllersLoading,
      ratifiers,
      ratifiersLoading,
      streets,
      streetsLoading,
      reportStates,
      reportStatesLoading,
      reportSentToProcessStates,
      reportSentToProcessStatesLoading,
      isComplaintViewerSent} = this.props;
    const vehiclesOptions = typeaheadOptionsBuilder.vehicles(vehicles);
    const streetsOptions = typeaheadOptionsBuilder.streets(streets);
    const routesOptions = selectOptionsBuilder.routes(routes);
    const lawsOptions = selectOptionsBuilder.laws(laws);
    const controllersOptions = selectOptionsBuilder.users(controllers);
    const ratifiersOptions = selectOptionsBuilder.users(ratifiers);
    const reportStatesOptions = selectOptionsBuilder.reportStates(reportStates);
    const reportSentToProcessStatesOptions = selectOptionsBuilder.reportSentToProcessStates(reportSentToProcessStates);
    const ratifiedOptions = selectOptionsBuilder.ratified();
    const controllerSignatureOptions = selectOptionsBuilder.controllerSignature();
    const ratifierSignatureOptions = selectOptionsBuilder.ratifierSignature();
    const filtersApplied: boolean = this.handleCheckFiltersApplied();

    return (
      <CardSectionCollapsed
        title={I18n.tr(I18nKeys.FILTROS)}
        initialCollapsed={!filtersApplied}
        className={"card-filters"}
      >
        <Row>
          <FilterInputSearch
            gridSize={3}
            label={I18n.tr(I18nKeys.NUMERO_DE_DENUNCIA)}
            initialValue={filterValues.reportNumber}
            onRef={(ref) => {
              this.refReportNumber = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("reportNumber", value)}
          />
          <FilterDate
            gridSize={3}
            label={I18n.tr(I18nKeys.FECHA_INICIO)}
            initialValue={filterValues.startDate}
            onRef={(ref) => {
              this.refStartDate = ref;

              return ref;
            }}
            onChange={(value: Date) => this.handleSetFilterValue("startDate", value ? value.toISOString() : "")}
          />
          <FilterDate
            gridSize={3}
            label={I18n.tr(I18nKeys.FECHA_FIN)}
            initialValue={filterValues.endDate}
            onRef={(ref) => {
              this.refEndDate = ref;

              return ref;
            }}
            onChange={(value: Date) => this.handleSetFilterValue("endDate", value ? value.toISOString() : "")}
          />
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.MATRICULA)}
            initialValue={filterValues.vehicleId}
            options={vehiclesOptions}
            isLoading={vehiclesLoading}
            onRef={(ref) => {
              this.refFilterVehicle = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("vehicleId", value)}
            onSearch={(query: string) => searchVehicle(query)}
          />
        </Row>
        <Row>
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.RUTA)}
            initialValue={filterValues.routeId}
            options={routesOptions}
            isLoading={routesLoading}
            onRef={(ref) => {
              this.refFilterRoute = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("routeId", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.ARTICULO_DE_LA_DENUNCIA)}
            initialValue={filterValues.lawId}
            options={lawsOptions}
            isLoading={lawsLoading}
            onRef={(ref) => {
              this.refFilterLaw = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("lawId", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.CONTROLADOR)}
            initialValue={filterValues.controllerId}
            options={controllersOptions}
            isLoading={controllersLoading}
            onRef={(ref) => {
              this.refFilterUserController = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("controllerId", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.RATIFICADOR)}
            initialValue={filterValues.ratifierId}
            options={ratifiersOptions}
            isLoading={ratifiersLoading}
            onRef={(ref) => {
              this.refFilterUserRatifier = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("ratifierId", value)}
          />
        </Row>
        <Row>
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.CALLE)}
            initialValue={filterValues.streetId}
            options={streetsOptions}
            isLoading={streetsLoading}
            onRef={(ref) => {
              this.refFilterStreet = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("streetId", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.ESTADO)}
            initialValue={filterValues.reportStateId}
            isLoading={reportStatesLoading}
            options={reportStatesOptions}
            onRef={(ref) => {
              this.refFilterReportState = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("reportStateId", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.ESTADO_ADVERACION)}
            initialValue={filterValues.ratified}
            options={ratifiedOptions}
            onRef={(ref) => {
              this.refFilterRatified = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("ratified", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.ESTADO_DE_TRAMITE_DE_DENUNCIA)}
            initialValue={filterValues.reportSentToProcessStateId}
            options={reportSentToProcessStatesOptions}
            isLoading={reportSentToProcessStatesLoading}
            disabled={isComplaintViewerSent}
            onRef={(ref) => {
              this.refFilterReportSentToProcessState = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("reportSentToProcessStateId", value)}
          />
        </Row>
        <Row>
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.DENUNCIA_FIRMADA)}
            initialValue={filterValues.controllerSignature}
            options={controllerSignatureOptions}
            onRef={(ref) => {
              this.refFilterControllerSignature = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("controllerSignature", value)}
          />
          <FilterSelect
            gridSize={3}
            label={I18n.tr(I18nKeys.ADVERACION_FIRMADA)}
            initialValue={filterValues.ratifierSignature}
            options={ratifierSignatureOptions}
            onRef={(ref) => {
              this.refFilterRatifierSignature = ref;

              return ref;
            }}
            onChange={(value: string) => this.handleSetFilterValue("ratifierSignature", value)}
          />
        </Row>
        <Row>
          <Col sm={9} md={9} lg={9}/>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <Button
                text={I18n.tr(I18nKeys.BORRAR_FILTROS)}
                onClick={this.handleClearFilter}
                type={"button"}
                block={true}
                className={"btn-lg btn-default btn-export"}
              />
            </div>
          </FormCol>
        </Row>
      </CardSectionCollapsed>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleParseFiltersToDataCriteria = (): ReportCriteriaProps => {
    const {reportNumber,
      startDate,
      endDate,
      vehicleId,
      routeId,
      lawId,
      controllerId,
      ratifierId,
      streetId,
      reportStateId,
      ratified,
      reportSentToProcessStateId,
      controllerSignature,
      ratifierSignature} = this.state.filterValues;

    return {
      number: reportNumber || undefined,
      start_date: startDate
        ? new Date(startDate).toISOString()
        : undefined,
      end_date: endDate
        ? DateHelpers.getXNextDays(1, new Date(endDate)).toISOString()
        : undefined,
      vehicle_ids: vehicleId && vehicleId !== "-1"
        ? [vehicleId]
        : undefined,
      route_ids: routeId && routeId !== "-1"
        ? [routeId]
        : undefined,
      law_ids: lawId && lawId !== "-1"
        ? [lawId]
        : undefined,
      controller_ids: controllerId && controllerId !== "-1"
        ? [controllerId]
        : undefined,
      ratifier_ids: ratifierId && ratifierId !== "-1"
        ? [ratifierId]
        : undefined,
      street_ids: streetId && streetId !== "-1"
        ? [streetId]
        : undefined,
      report_state_ids: reportStateId && reportStateId !== "-1"
        ? [reportStateId]
        : undefined,
      ratified: ratified && ratified !== "-1"
        ? ratified === "true"
        : undefined,
      report_sent_to_process_state_ids: reportSentToProcessStateId && reportSentToProcessStateId !== "-1"
        ? [reportSentToProcessStateId]
        : undefined,
      signature_controller: controllerSignature && controllerSignature !== "-1"
        ? controllerSignature === "true"
        : undefined,
      signature_ratifier: ratifierSignature && ratifierSignature !== "-1"
        ? ratifierSignature === "true"
        : undefined,
    };
  };

  private handleSetFilterValue = (id: string, value: string) => {
    this.setState(
      (prevState: State) => (
        {
          ...prevState,
          filterValues: {
            ...prevState.filterValues,
            [id]: value,
          },
        }
      ),
      () => {
        this.props.onAddCriteria(this.handleParseFiltersToDataCriteria());
        ReportListCompanyUserStorageManager.setFilters(this.state.filterValues);
      },
    );
  };

  private handleResetFilterValues = () => {
    this.setState(
      (prevState: State) => (
        {
          ...prevState,
          filterValues: {
            ...INITIAL_FILTER_VALUES,
            reportSentToProcessStateId: this.props.isComplaintViewerSent
              ? ReportSentToProcessStateIds.ENVIADA
              : "",
          },
        }
      ),
      () => {
        this.props.onAddCriteria(this.handleParseFiltersToDataCriteria());
        ReportListCompanyUserStorageManager.removeAll();
      },
    );
  };

  private handleCheckFiltersApplied = (): boolean => {
    const {reportNumber,
      startDate,
      endDate,
      vehicleId,
      routeId,
      lawId,
      controllerId,
      ratifierId,
      streetId,
      reportStateId,
      ratified,
      reportSentToProcessStateId,
      controllerSignature,
      ratifierSignature} = this.state.filterValues;

    return !!reportNumber
            || !!startDate
            || !!endDate
            || (!!vehicleId && vehicleId !== "-1")
            || (!!routeId && routeId !== "-1")
            || (!!lawId && lawId !== "-1")
            || (!!controllerId && controllerId !== "-1")
            || (!!ratifierId && ratifierId !== "-1")
            || (!!streetId && streetId !== "-1")
            || (!!reportStateId && reportStateId !== "-1")
            || (!!ratified && ratified !== "-1")
            || (!!reportSentToProcessStateId && reportSentToProcessStateId !== "-1")
            || (!!controllerSignature && controllerSignature !== "-1")
            || (!!ratifierSignature && ratifierSignature !== "-1");
  };

  private handleClearFilter = () => {
    const {isComplaintViewerSent} = this.props;

    this.refReportNumber.value = "";
    this.refStartDate.value = "";
    this.refEndDate.value = "";
    this.refFilterVehicle.getInstance().clear();
    this.refFilterRoute.value = "";
    this.refFilterLaw.value = "";
    this.refFilterUserController.value = "";
    this.refFilterUserRatifier.value = "";
    this.refFilterStreet.getInstance().clear();
    this.refFilterReportState.value = "";
    this.refFilterRatified.value = "";
    this.refFilterControllerSignature.value = "";
    this.refFilterRatifierSignature.value = "";

    if (!isComplaintViewerSent) 
      this.refFilterReportSentToProcessState.value = "";

    this.handleResetFilterValues();
    this.props.onClearCriteria();
  };
}

export default connect(mapStateToProps, {
} )(ReportListCompanyUserFilter) as React.ComponentType<InnerProps>;
