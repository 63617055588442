type TranslationType = { [key: string]: string };
type TranslationsType = {[key: string]: TranslationType};

type TrSettings = {
  language: string;
};

const translations: TranslationsType = {
};
let currentLocale: string;

export default class I18n {
  static putLocale(locale: string, translation: TranslationType) {
    translations[locale] = translation;
  }

  static putLocaleEntry(locale: string, key: string, value: string) {
    translations[locale][key] = value;
  }

  static tr(key: string, settings?: TrSettings): string {
    const ret = translations[settings?.language ?? currentLocale][key];

    if (!ret)
      return key;

    return ret;
  }

  static getLocale(): string {
    return currentLocale;
  }

  static setLocale(locale: string): void {
    currentLocale = locale;
  }
}