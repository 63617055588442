import Config from "../../config/Config";

const {BASE_URL} = Config;

export default class {
  public static URL_LOGIN = `${BASE_URL}/auth/login`;

  public static URL_RECOVER_PASSWORD = `${BASE_URL}/auth/recoverpassword`;

  public static URL_USER_SET_PASSWORD = `${BASE_URL}/auth/setpassword`;

  public static URL_USER_CURRENT_DETAIL = `${BASE_URL}/user/current`;

  public static URL_USER_LIST = `${BASE_URL}/user`;

  public static URL_USER_ADD = `${BASE_URL}/user/add`;

  public static URL_USER_DETAIL = `${BASE_URL}/user/:uuid`;

  public static URL_USER_EDIT = `${BASE_URL}/user/:uuid`;

  public static URL_USER_DELETE = `${BASE_URL}/user/:uuid`;

  public static URL_USER_DELETE_MULTIPLE = `${BASE_URL}/user`;

  public static URL_USER_CHANGE_PASSWORD = `${BASE_URL}/user/password/:uuid`;

  public static URL_EXPLOITATION_LIST = `${BASE_URL}/exploitation`;

  public static URL_EXPLOITATION_ADD = `${BASE_URL}/exploitation/add`;

  public static URL_EXPLOITATION_DETAIL = `${BASE_URL}/exploitation/:uuid`;

  public static URL_EXPLOITATION_EDIT = `${BASE_URL}/exploitation/:uuid`;

  public static URL_EXPLOITATION_DELETE = `${BASE_URL}/exploitation/:uuid`;

  public static URL_EXPLOITATION_DELETE_MULTIPLE = `${BASE_URL}/exploitation`;

  public static URL_USER_ROLE_LIST = `${BASE_URL}/user/role`;

  public static URL_USER_TYPE_LIST = `${BASE_URL}/user/type`;

  public static URL_ROAD_TYPE_LIST = `${BASE_URL}/road/type`;

  public static URL_PARKING_AREA_TYPE_LIST = `${BASE_URL}/parking/area/type`;

  public static URL_PARKING_AREA_TYPE_ADD = `${BASE_URL}/parking/area/type/add`;

  public static URL_PARKING_AREA_TYPE_DETAIL = `${BASE_URL}/parking/area/type/:uuid`;

  public static URL_PARKING_AREA_TYPE_EDIT = `${BASE_URL}/parking/area/type/:uuid`;

  public static URL_PARKING_AREA_TYPE_DELETE = `${BASE_URL}/parking/area/type/:uuid`;

  public static URL_PARKING_AREA_TYPE_DELETE_MULTIPLE = `${BASE_URL}/parking/area/type`;

  public static URL_ROUTE_LIST = `${BASE_URL}/route`;

  public static URL_ROUTE_ADD = `${BASE_URL}/route/add`;

  public static URL_ROUTE_DETAIL = `${BASE_URL}/route/:uuid`;

  public static URL_ROUTE_EDIT = `${BASE_URL}/route/:uuid`;

  public static URL_ROUTE_DELETE = `${BASE_URL}/route/:uuid`;

  public static URL_ROUTE_DELETE_MULTIPLE = `${BASE_URL}/route`;

  public static URL_STREET_LIST = `${BASE_URL}/street`;

  public static URL_STREET_LIST_SEARCH_BY_POSITION = `${BASE_URL}/street/search`;

  public static URL_STREET_ADD = `${BASE_URL}/street/add`;

  public static URL_STREET_DETAIL = `${BASE_URL}/street/:uuid`;

  public static URL_STREET_EDIT = `${BASE_URL}/street/:uuid`;

  public static URL_STREET_DELETE = `${BASE_URL}/street/:uuid`;

  public static URL_STREET_DELETE_MULTIPLE = `${BASE_URL}/street`;

  public static URL_STREET_TYPE_LIST = `${BASE_URL}/street/type`;

  public static URL_VEHICLE_COLOR_LIST = `${BASE_URL}/vehicle/color`;

  public static URL_VEHICLE_COLOR_ADD = `${BASE_URL}/vehicle/color/add`;

  public static URL_VEHICLE_COLOR_DETAIL = `${BASE_URL}/vehicle/color/:uuid`;

  public static URL_VEHICLE_COLOR_EDIT = `${BASE_URL}/vehicle/color/:uuid`;

  public static URL_VEHICLE_COLOR_DELETE = `${BASE_URL}/vehicle/color/:uuid`;

  public static URL_VEHICLE_COLOR_DELETE_MULTIPLE = `${BASE_URL}/vehicle/color`;

  public static URL_VEHICLE_BRAND_LIST = `${BASE_URL}/vehicle/brand`;

  public static URL_VEHICLE_BRAND_ADD = `${BASE_URL}/vehicle/brand/add`;

  public static URL_VEHICLE_BRAND_DETAIL = `${BASE_URL}/vehicle/brand/:uuid`;

  public static URL_VEHICLE_BRAND_EDIT = `${BASE_URL}/vehicle/brand/:uuid`;

  public static URL_VEHICLE_BRAND_DELETE = `${BASE_URL}/vehicle/brand/:uuid`;

  public static URL_VEHICLE_BRAND_DELETE_MULTIPLE = `${BASE_URL}/vehicle/brand`;

  public static URL_VEHICLE_BLACKLIST_EXPORT = `${BASE_URL}/vehicle/blacklist/export`;

  public static URL_VEHICLE_MODEL_LIST = `${BASE_URL}/vehicle/model`;

  public static URL_VEHICLE_MODEL_ADD = `${BASE_URL}/vehicle/model/add`;

  public static URL_VEHICLE_MODEL_DETAIL = `${BASE_URL}/vehicle/model/:uuid`;

  public static URL_VEHICLE_MODEL_EDIT = `${BASE_URL}/vehicle/model/:uuid`;

  public static URL_VEHICLE_MODEL_DELETE = `${BASE_URL}/vehicle/model/:uuid`;

  public static URL_VEHICLE_MODEL_DELETE_MULTIPLE = `${BASE_URL}/vehicle/model`;

  public static URL_VEHICLE_TYPE_LIST = `${BASE_URL}/vehicle/type`;

  public static URL_VEHICLE_LICENSE_PLATE_DETAIL = `${BASE_URL}/vehicle/:uuid`;

  public static URL_VEHICLE_LIST = `${BASE_URL}/vehicle`;

  public static URL_VEHICLE_ADD = `${BASE_URL}/vehicle/add`;

  public static URL_VEHICLE_DETAIL = `${BASE_URL}/vehicle/:uuid`;

  public static URL_VEHICLE_EDIT = `${BASE_URL}/vehicle/:uuid`;

  public static URL_VEHICLE_DELETE = `${BASE_URL}/vehicle/:uuid`;

  public static URL_VEHICLE_DELETE_MULTIPLE = `${BASE_URL}/vehicle`;

  public static URL_VEHICLE_LICENSE_PLATE_DETAIL_SCAN = `${BASE_URL}/vehicle/license/:license`;

  public static URL_VEHICLE_LICENSE_PLATE_LIST = `${BASE_URL}/vehicle/licenseplatemask`;

  public static URL_VEHICLE_LICENSE_PLATE_MASK = `${BASE_URL}/vehicle/licenseplatemask/:license_plate`;

  public static URL_BLACK_LIST_RULE_LIST = `${BASE_URL}/blacklist/rule`;

  public static URL_BLACK_LIST_RULE_ADD = `${BASE_URL}/blacklist/rule/add`;

  public static URL_BLACK_LIST_RULE_DETAIL = `${BASE_URL}/blacklist/rule/:uuid`;

  public static URL_BLACK_LIST_RULE_EDIT = `${BASE_URL}/blacklist/rule/:uuid`;

  public static URL_BLACK_LIST_RULE_DELETE = `${BASE_URL}/blacklist/rule/:uuid`;

  public static URL_BLACK_LIST_RULE_DELETE_MULTIPLE = `${BASE_URL}/blacklist/rule`;

  public static URL_LAW_TYPE_LIST = `${BASE_URL}/law/type`;

  public static URL_LAW_TYPE_ADD = `${BASE_URL}/law/type/add`;

  public static URL_LAW_TYPE_DETAIL = `${BASE_URL}/law/type/:uuid`;

  public static URL_LAW_TYPE_EDIT = `${BASE_URL}/law/type/:uuid`;

  public static URL_LAW_TYPE_DELETE = `${BASE_URL}/law/type/:uuid`;

  public static URL_LAW_TYPE_DELETE_MULTIPLE = `${BASE_URL}/law/type`;

  public static URL_CANCELLATION_REFERENCE_TYPE_LIST = `${BASE_URL}/cancellationreference/type`;

  public static URL_LAW_LIST = `${BASE_URL}/law`;

  public static URL_LAW_ADD = `${BASE_URL}/law/add`;

  public static URL_LAW_DETAIL = `${BASE_URL}/law/:uuid`;

  public static URL_LAW_EDIT = `${BASE_URL}/law/:uuid`;

  public static URL_LAW_DELETE = `${BASE_URL}/law/:uuid`;

  public static URL_LAW_DELETE_MULTIPLE = `${BASE_URL}/law`;

  public static URL_PARKING_METER_LIST = `${BASE_URL}/parkingmeter`;

  public static URL_PARKING_METER_ADD = `${BASE_URL}/parkingmeter/add`;

  public static URL_PARKING_METER_DETAIL = `${BASE_URL}/parkingmeter/:uuid`;

  public static URL_PARKING_METER_EDIT = `${BASE_URL}/parkingmeter/:uuid`;

  public static URL_PARKING_METER_DELETE = `${BASE_URL}/parkingmeter/:uuid`;

  public static URL_PARKING_METER_DELETE_MULTIPLE = `${BASE_URL}/parkingmeter`;

  public static URL_PARKING_METER_INCIDENCE_STATE_LIST = `${BASE_URL}/parkingmeter/incidence/state`;

  public static URL_PARKING_METER_INCIDENCE_TYPE_LIST = `${BASE_URL}/parkingmeter/incidence/type`;

  public static URL_PARKING_METER_TYPE_LIST = `${BASE_URL}/parkingmeter/type`;

  public static URL_PARKING_METER_INCIDENCE_LIST = `${BASE_URL}/parkingmeter/incidence`;

  public static URL_PARKING_METER_INCIDENCE_ADD = `${BASE_URL}/parkingmeter/incidence/add`;

  public static URL_PARKING_METER_INCIDENCE_DETAIL = `${BASE_URL}/parkingmeter/incidence/:uuid`;

  public static URL_PARKING_METER_INCIDENCE_EDIT = `${BASE_URL}/parkingmeter/incidence/:uuid`;

  public static URL_PARKING_METER_INCIDENCE_DELETE = `${BASE_URL}/parkingmeter/incidence/:uuid`;

  public static URL_PARKING_METER_INCIDENCE_DELETE_MULTIPLE = `${BASE_URL}/parkingmeter/incidence`;

  public static URL_PARKING_METER_INCIDENCE_SOLVE = `${BASE_URL}/parkingmeter/incidence/solve/:uuid`;

  public static URL_PARKING_METER_COLLECTION_LIST = `${BASE_URL}/parkingmeter/collection`;

  public static URL_PARKING_METER_COLLECTION_ADD = `${BASE_URL}/parkingmeter/collection/add`;

  public static URL_PARKING_METER_COLLECTION_DETAIL = `${BASE_URL}/parkingmeter/collection/:uuid`;

  public static URL_PARKING_METER_COLLECTION_EDIT = `${BASE_URL}/parkingmeter/collection/:uuid`;

  public static URL_PARKING_METER_COLLECTION_DELETE = `${BASE_URL}/parkingmeter/collection/:uuid`;

  public static URL_PARKING_METER_COLLECTION_DELETE_MULTIPLE = `${BASE_URL}/parkingmeter/collection`;

  public static URL_PARKING_METER_COLLECTION_EXPORT = `${BASE_URL}/parkingmeter/collection/export`;

  public static URL_PARKING_LOSS_REASON_LIST = `${BASE_URL}/parking/loss/reason`;

  public static URL_PARKING_LOSS_REASON_ADD = `${BASE_URL}/parking/loss/reason/add`;

  public static URL_PARKING_LOSS_REASON_DETAIL = `${BASE_URL}/parking/loss/reason/:uuid`;

  public static URL_PARKING_LOSS_REASON_EDIT = `${BASE_URL}/parking/loss/reason/:uuid`;

  public static URL_PARKING_LOSS_REASON_DELETE = `${BASE_URL}/parking/loss/reason/:uuid`;

  public static URL_PARKING_LOSS_REASON_DELETE_MULTIPLE = `${BASE_URL}/parking/loss/reason`;

  public static URL_PARKING_LOSS_LIST = `${BASE_URL}/parking/loss`;

  public static URL_PARKING_LOSS_ADD = `${BASE_URL}/parking/loss/add`;

  public static URL_PARKING_LOSS_DETAIL = `${BASE_URL}/parking/loss/:uuid`;

  public static URL_PARKING_LOSS_EDIT = `${BASE_URL}/parking/loss/:uuid`;

  public static URL_PARKING_LOSS_DELETE = `${BASE_URL}/parking/loss/:uuid`;

  public static URL_PARKING_LOSS_DELETE_MULTIPLE = `${BASE_URL}/parking/loss`;

  public static URL_PARKING_LOSS_EXPORT_EXCEL = `${BASE_URL}/parking/loss/export`;

  public static URL_REPORT_LIST = `${BASE_URL}/report`;

  public static URL_REPORT_ADD = `${BASE_URL}/report/add`;

  public static URL_REPORT_DETAIL = `${BASE_URL}/report/:uuid`;

  public static URL_REPORT_EDIT = `${BASE_URL}/report/:uuid`;

  public static URL_REPORT_DELETE = `${BASE_URL}/report/:uuid`;

  public static URL_REPORT_DELETE_MULTIPLE = `${BASE_URL}/report`;

  public static URL_REPORT_EXPORT_PROCESSING = `${BASE_URL}/report/export/processing`;

  public static URL_REPORT_EXPORT_EXCEL = `${BASE_URL}/report/export`;

  public static URL_REPORT_RATIFIER = `${BASE_URL}/report/ratifier/:uuid`;

  public static URL_REPORT_RATIFIER_CANCEL = `${BASE_URL}/report/ratifier/:uuid/cancel`;

  public static URL_REPORT_GET_PDF = `${BASE_URL}/report/:uuid/pdf`;

  public static URL_REPORT_GENERATE_NUMBER = `${BASE_URL}/report/:uuid/generatenumber`;

  public static URL_REPORT_STATE_LIST = `${BASE_URL}/report/state`;

  public static URL_REPORT_STATE_CANCEL = `${BASE_URL}/report/state/cancel`;

  public static URL_REPORT_STATE_CANCEL_FILE = `${BASE_URL}/report/state/cancel/file`;

  public static URL_REPORT_STATE_INVALIDATE = `${BASE_URL}/report/state/invalidate`;

  public static URL_REPORT_STATE_RECTIFY = `${BASE_URL}/report/state/rectify`;

  public static URL_REPORT_SENT_TO_PROCESS_STATE_LIST = `${BASE_URL}/report/senttoprocess/state`;

  public static URL_REPORT_SENT_TO_PROCESS_STATE_MARK_TO_SEND = `${BASE_URL}/report/senttoprocess/state/marktosend`;

  public static URL_REPORT_SENT_TO_PROCESS_STATE_CANCEL_TO_SEND = `${BASE_URL}/report/senttoprocess/state/canceltosend`;

  public static URL_REPORT_SENT_TO_PROCESS_STATE_CLAIM = `${BASE_URL}/report/senttoprocess/state/claim`;

  public static URL_REPORT_SENT_TO_PROCESS_STATE_RETURN_CANCELLATION = `${BASE_URL}/report/senttoprocess/state/returncancellation`;

  public static URL_REPORT_CANCELLATION_METHOD_LIST = `${BASE_URL}/report/cancellationmethod`;

  public static URL_REPORT_CANCELLATION_METHOD_DETAIL = `${BASE_URL}/report/cancellationmethod/:uuid`;

  public static URL_REPORT_CANCELLATION_METHOD_ADD = `${BASE_URL}/report/cancellationmethod/add`;

  public static URL_REPORT_CANCELLATION_METHOD_EDIT = `${BASE_URL}/report/cancellationmethod/:uuid`;

  public static URL_REPORT_CANCELLATION_METHOD_DELETE = `${BASE_URL}/report/cancellationmethod/:uuid`;

  public static URL_REPORT_CANCELLATION_METHOD_DELETE_MULTIPLE = `${BASE_URL}/report/cancellationmethod`;

  public static URL_REPORT_INVALIDATION_REASON_LIST = `${BASE_URL}/report/invalidationreason`;

  public static URL_REPORT_INVALIDATION_REASON_DETAIL = `${BASE_URL}/report/invalidationreason/:uuid`;

  public static URL_REPORT_INVALIDATION_REASON_ADD = `${BASE_URL}/report/invalidationreason/add`;

  public static URL_REPORT_INVALIDATION_REASON_EDIT = `${BASE_URL}/report/invalidationreason/:uuid`;

  public static URL_REPORT_INVALIDATION_REASON_DELETE = `${BASE_URL}/report/invalidationreason/:uuid`;

  public static URL_REPORT_INVALIDATION_REASON_DELETE_MULTIPLE = `${BASE_URL}/report/invalidationreason`;

  public static URL_REPORT_CANCELLATION_LOG_LIST = `${BASE_URL}/report/cancellation/log`;

  public static URL_REPORT_CANCELLATION_LOG_EXPORT = `${BASE_URL}/report/cancellation/log/export`;

  public static URL_REPORT_EXPORT_HISTORY = `${BASE_URL}/report/export/history`;

  public static URL_STREET_SITUATION_LIST = `${BASE_URL}/street/situation`;

  public static URL_WORKDAY_LIST = `${BASE_URL}/workday`;

  public static URL_WORKDAY_DETAIL = `${BASE_URL}/workday/:uuid`;

  public static URL_WORKDAY_CURRENT = `${BASE_URL}/workday/current`;

  public static URL_WORKDAY_START = `${BASE_URL}/workday/start`;

  public static URL_WORKDAY_SET_STATE = `${BASE_URL}/workday/setstate`;

  public static URL_WORKDAY_FINISH = `${BASE_URL}/workday/finish`;

  public static URL_WORKDAY_STATE_LIST = `${BASE_URL}/workday/state`;

  public static URL_WORKDAY_STATE_DETAIL = `${BASE_URL}/workday/state/:uuid`;

  public static URL_WORKDAY_STATE_ADD = `${BASE_URL}/workday/state/add`;

  public static URL_WORKDAY_STATE_EDIT = `${BASE_URL}/workday/state/:uuid`;

  public static URL_WORKDAY_STATE_DELETE = `${BASE_URL}/workday/state/:uuid`;

  public static URL_WORKDAY_STATE_DELETE_MULTIPLE = `${BASE_URL}/workday/state`;

  public static URL_WORKDAY_POSITION_ADD = `${BASE_URL}/workday/position/add`;

  public static URL_WORKDAY_POSITION_CHECK = `${BASE_URL}/workday/position/check`;

  public static URL_WORKDAY_RESUME = `${BASE_URL}/workday/resume`;

  public static URL_WORKDAY_REPORT = `${BASE_URL}/workday/report`;

  public static URL_WORKDAY_REPORT_EXPORT = `${BASE_URL}/workday/report/export`;

  public static URL_LOG_LIST = `${BASE_URL}/logs`;

  public static URL_LOG_TYPE_LIST = `${BASE_URL}/logtypes`;

  public static URL_FILE_UPLOAD = `${BASE_URL}/upload`;

  public static URL_CHAT_MESSAGE_LIST = `${BASE_URL}/chat/message`;

  public static URL_CHAT_MESSAGE_ADD = `${BASE_URL}/chat/message/add`;

  public static URL_CHAT_BROADCAST_LIST = `${BASE_URL}/chat/broadcast`;

  public static URL_CHAT_BROADCAST_ADD = `${BASE_URL}/chat/broadcast/add`;

  public static URL_CHAT_CONTACT_LIST = `${BASE_URL}/chat/contacts`;

  public static URL_CHAT_CONNECTED_IDS = `${BASE_URL}/chat/contacts/connected/ids`;

  public static URL_MANAGEMENT_PROCESSING_TYPE_LIST = `${BASE_URL}/managementprocessing/type`;

  public static URL_USER_CERT = `${BASE_URL}/user/cert`;

  public static URL_USER_CERT_DELETE = `${BASE_URL}/user/cert`;

  public static URL_USER_CERT_UPLOAD = `${BASE_URL}/user/cert`;

  public static URL_CERT_CHECK = `${BASE_URL}/user/cert/check`;

  public static URL_APP_INFO_DETAIL = `${BASE_URL}/appinfo/details`;

  public static URL_APP_INFO_EDIT = `${BASE_URL}/appinfo/edit`;

  public static URL_DASHBOARD_INFORMATION = `${BASE_URL}/dashboard/information`;

  public static URL_SOS = `${BASE_URL}/sos`;

  public static URL_PARKING_METER_COLLECTION_PROCESS_LIST = `${BASE_URL}/parkingmeter/collectionprocess`;

  public static URL_PARKING_METER_COLLECTION_PROCESS_DETAIL = `${BASE_URL}/parkingmeter/collectionprocess/:uuid`;

  public static URL_PARKING_METER_COLLECTION_PROCESS_ADD = `${BASE_URL}/parkingmeter/collectionprocess/add`;

  public static URL_PARKING_METER_COLLECTION_PROCESS_CORRECTION = `${BASE_URL}/parkingmeter/collectionprocess/:uuid/correction`;

  public static URL_PARKING_METER_COLLECTION_PROCESS_SEND = `${BASE_URL}/parkingmeter/collectionprocess/send`;

  public static URL_DELTA_CAR_LIST = `${BASE_URL}/deltacar`;

  public static URL_DELTA_CAR_ADD = `${BASE_URL}/deltacar/add`;

  public static URL_DELTA_CAR_DETAIL = `${BASE_URL}/deltacar/:uuid`;

  public static URL_DELTA_CAR_EDIT = `${BASE_URL}/deltacar/:uuid`;

  public static URL_DELTA_CAR_DELETE = `${BASE_URL}/deltacar/:uuid`;

  public static URL_DELTA_CAR_DELETE_MULTIPLE = `${BASE_URL}/deltacar`;

  public static URL_WORKDAY_TYPE_LIST = `${BASE_URL}/workday/type`;

  public static URL_DELTACAR_LIST = `${BASE_URL}/deltacar`;

  public static URL_STREET_SMART_PARK_ID_LIST = `${BASE_URL}/streetsmart/parkids`;
}
